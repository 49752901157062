import '../../App.css'
import HeroSection from '../HeroSection';
import React from 'react';
import Cards from '../Cards';
import Navbar from '../../components/Navbar';
//import Footer from '../Footer';
//import { useRef } from 'react';





function Home() {


    return (
        <>
            <Navbar />
            <HeroSection />
            <Cards />
            
            
        </>
    )
}

export default Home;