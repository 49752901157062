import '../../App.css'
import React from 'react';
import Peach from '../Peach';


function Peaches() {
    return (
        <>
            <Peach />
        </>
    )
}

export default Peaches;