import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import { Link } from 'react-router-dom';
//<video src='/videos/video-2.mp4' autoPlay loop muted />

const scrollToBottom = () => {
    window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
        /* you can also use 'auto' behaviour 
           in place of 'smooth' */
    });
};



function HeroSection() {
    return (
        <div className='hero-container'>

            <h1><p><Link to='/peach' id="home"><img className="image_on" src="/images/botbot_transparent.png" alt="kirby" /><img className="image_off" src="/images/peach_fruit2.png" alt="logo" /></Link>BOTBOT2222</p></h1>
            

            <p>The chatbot of all time</p>
            <div className='hero-btns'>
                <Button
                    className='btns'
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'
                    onClick={scrollToBottom}
                >
                    FEATURES
                </Button>
                <Button
                    className='btns'
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'
                    //onClick={console.log('hey')}
                >
                    <Link to='/addme'style={{ textDecoration: 'none' }}>
                        ADD ME TO YOUR CHANNEL<i className='far fa-play-circle' />
                    </Link>

                </Button>
            </div>
        </div>
    );
}

export default HeroSection;