import '../../App.css'
import React from 'react';
import Peach2 from '../Peach2';


function Peaches2() {
    return (
        <>
            <Peach2 />
        </>
    )
}

export default Peaches2;