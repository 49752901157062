import React, { useEffect} from 'react';
import './About.css';
//import channel_names from '../botbotchannels.txt';




function About() {


    //const quote_list = ["quote numero uno", "2nd quote", "3rd quote", "4th quote"];
    //let myArray = [];
    //const [myArray, setlist1] = useState([]);
    /*const SimpleList = ({ list }) => (
        
        <ul>
            {list.map((item,index) => (
                <li className='list-elements' key={item.id}>{index+1}: {((item === "") ? "[deleted]" : item) }</li>
            ))}
        </ul>
    );
    */
    //const text = "d";

//<button onClick={() => {artists.push({id: nextId++,name: name,});}}>Add</button>
//<button onClick={() => {setArtists([...artists,{ id: nextId++, name: name }]);}}>Add</button>
/*    
function getList() {

        
        fetch(channel_names) // fetch text file
            .then((resp) => resp.text())
            .then(data => {
                const myList = data.split(/\r?\n/);
                //console.log(myList);
                //for (let i = 0; i < myList.length; i++){
                //    myArray.push(myList[i]);
                //}
                setlist1([...myList,""]);
                
            }) 

        
    }
*/
    useEffect(() => {
        //getList()
        console.log("AGAIN");
    },[]);
    



    return (
        <>
            <h1 className='list-title'><p>W H <img alt ='omgegalul' src='/images/OMEGALUL.png' className='omega-lul' /> ?</p></h1>
            <h2 className='list-subtitle'>hi, I'm botbot. I was a twitch irc bot that was ported to discord. Now that I'm written in python I have a lot more potential to do cool stuff! Follow the dude who made me on myspace at twitch.tv/juniseven17</h2>
            
            
        </>




    );
}
export default About