import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
//import { Button } from './Button';


function Navbar() {
    const [click, setClick] = useState(false);

    //const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);

    const closeMobileMenu = function () {

        setClick(false);
        scrollToTop();
    }

    const scrollToTop = () => {
        window.scrollTo({
            top:0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    };
    /*
    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    }
    
    useEffect(() => {
        showButton()
    }, []);

    window.addEventListener('resize', showButton);
    */
    return (
        <>
            <nav className="navbar">
                <div className="navbar-container">
                    <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                        <img className="botbot_img" src="/images/botbot_transparent.png" alt="kirby" />
                        Botbot2222
                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/quotes' className='nav-links' onClick={closeMobileMenu}>
                                Quotes
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/commands' className='nav-links' onClick={closeMobileMenu}>
                                Commands
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link to='/channels' className='nav-links' onClick={closeMobileMenu}>
                                Channels
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
                                About
                            </Link>
                        </li>
                        <li>
                            <Link to='/addme' className='nav-links-mobile' onClick={closeMobileMenu}>
                                ADD ME
                            </Link>
                        </li>
                    </ul>
                    
                </div>
            </nav>

        </>
    )
}

export default Navbar
