import '../../App.css'
import React from 'react';
import Quote from '../Quote';
import Navbar from '../../components/Navbar';


function Quotes() {
    return (
        <>
            <Navbar />
            <Quote />
        </>
    )
}

export default Quotes;