import React, { useEffect } from 'react';
import './Peach.css';


function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}




function spawnPeach2(delay) {
    var oImg = document.createElement("img");

    const flipped = getRandomInt(2);
    //console.log(flipped);
    const img_str = 'images/peaches_img/peach' + getRandomInt(90) + '.png';
    const img_top = (getRandomInt(100) ) + 'vh';
    const img_left = (getRandomInt(100)) + 'vw';
    const size = getRandomInt(25) + 10;
    const img_height = size + 'vw';
    const img_width = size + 'vh';
    const rotate = (-20 + getRandomInt(40)) + 'deg';

    oImg.setAttribute('style', `transform: translate(-50%, -50%) scaleX(${(flipped === 0) ? 1 : -1});top:${img_top};left:${img_left};height:${img_height};width:${img_width};rotate:${rotate}`);
    oImg.setAttribute('class', 'peach_pic');
    oImg.setAttribute('alt', 'peach');
    oImg.setAttribute('src', img_str);
    //console.log(delay);
    setTimeout(function(){
        document.getElementById('peach-id').appendChild(oImg)
    },delay);

}



function nothing (){

}


function cleanup() {
    var highestTimeoutId = setTimeout(nothing);
    for (var i = 0; i < highestTimeoutId; i++) {
        clearTimeout(i);
    }

    var highestIntervalId = setInterval(nothing);
    for (var j = 0; j < highestIntervalId; j++) {
        clearInterval(j);
    }
}

function start_theshow(){
    cleanup();

    var start_button = document.getElementById("start_button");
    start_button.style.animation="fade-out 2s forwards";
    setTimeout(function() {
        start_button.style.zIndex="-2"
    },2000);


    var audio = document.getElementById("player");

    audio.src = 'music/peaches.m4a';
    audio.volume = 0;
    audio.load()
    audio.play()


    setTimeout(start_anim0, 2000);
    setTimeout(start_anim, 12400);
    
    
}






function start_anim0() {
    //console.log('start_anim0');

    var audio = document.getElementById("player");


    audio.currentTime = 0;
    audio.volume= 0.5;
    audio.play()
    var delay = 0;




    delay += 8200;
    spawnPeach2(delay);;//one and only princess peach 
}


function start_anim() {
    //console.log('start_anim');

    var audio = document.getElementById("player");


    audio.currentTime = 10.400;
    audio.volume= 0.5;
    audio.play()
    var delay = 0;

    delay += 600;
    spawnPeach2(delay);;//peach so cool
    delay += 10333;
    spawnPeach2(delay);;//peach understand

    delay += 10333;
    spawnPeach2(delay);;//peaches
    delay += 667;
    spawnPeach2(delay);;//peaches
    delay += 500;
    spawnPeach2(delay);;//peaches
    delay += 400;
    spawnPeach2(delay);;//peaches
    delay += 300;
    spawnPeach2(delay);;//peaches

    delay += 666;
    spawnPeach2(delay);;//peaches
    delay += 667;
    spawnPeach2(delay);;//peaches
    delay += 500;
    spawnPeach2(delay);;//peaches
    delay += 500;
    spawnPeach2(delay);;//peaches
    delay += 400;
    spawnPeach2(delay);;//peaches

    delay += 5800;
    spawnPeach2(delay);;//peaches
    delay += 667;
    spawnPeach2(delay);;//peaches
    delay += 500;
    spawnPeach2(delay);;//peaches
    delay += 400;
    spawnPeach2(delay);;//peaches
    delay += 300;
    spawnPeach2(delay);;//peaches

    delay += 666;
    spawnPeach2(delay);;//peaches
    delay += 667;
    spawnPeach2(delay);;//peaches
    delay += 500;
    spawnPeach2(delay);;//peaches
    delay += 400;
    spawnPeach2(delay);;//peaches
    delay += 300;
    spawnPeach2(delay);;//peaches


    delay += 17333;
    spawnPeach2(delay);;//princess peach at the end of the line


    delay += 9666;
    spawnPeach2(delay);;//peaches
    delay += 667;
    spawnPeach2(delay);;//peaches
    delay += 500;
    spawnPeach2(delay);;//peaches
    delay += 400;
    spawnPeach2(delay);;//peaches
    delay += 300;
    spawnPeach2(delay);;//peaches

    delay += 666;
    spawnPeach2(delay);;//peaches
    delay += 667;
    spawnPeach2(delay);;//peaches
    delay += 500;
    spawnPeach2(delay);;//peaches
    delay += 400;
    spawnPeach2(delay);;//peaches
    delay += 300;
    spawnPeach2(delay);;//peaches



    delay += 6000;
    spawnPeach2(delay);;//PEACHES

    delay += 2100;
    spawnPeach2(delay);;//PEACHES

    delay += 2600;
    spawnPeach2(delay);;//PEACHAAAA

    //delay += 2500;
   // spawnPeach2(delay);;//PEAAAAAAAA
   setTimeout(start_anim, 80000)


}

function Peach() {

    useEffect(() => {
        // Applying on mount
        document.body.style.overflow = "hidden";
        // Applying on unmount    
        return () => {
            document.body.style.overflow = "visible";
        }
    }, [])


    //const funRef = useRef(null);


    useEffect(() => {
        //funRef.current = setInterval(spawnPeach2, 333);
        //funRef.current = setTimeout(spawnPeach2,8333);

        return () => {
            //clearInterval(funRef.current);
            //clearTimeout(funRef.current);
            cleanup();
        };
    }, []);




    /*
    spawnPeach3(0,0,0,25)
    spawnPeach3(0,100,0,25)
    spawnPeach3(0,0,100,25)
    spawnPeach3(0,100,100,25)

    spawnPeach3(0,0,0,0)
    spawnPeach3(0,100,0,0)
    spawnPeach3(0,0,100,0)
    spawnPeach3(0,100,100,0)
    */

    return (

        <>
            <div className='parent-container'>
                <div className='bowser-sky'/>
                <div className='bowser-island' />
                <div id = "start_button" className='start-button' onClick={start_theshow}/>
                <audio id="player" hidden src='' />

                <div id='peach-id' className='peach-container'>

                </div>


            </div>


        </>




    );
}
export default Peach