import React, {useState, useEffect} from 'react';
import './Command.css';
import { useParams, Link } from 'react-router-dom';





function Command() {
    
    const { url_page } = useParams();
    //("this.context:",  url_page )

    if (url_page === undefined) {
        return Command_main()
    }
    else {

        return Command_Channel(url_page.toLowerCase());
    }

}


function Command_Channel(channel){


    function getCommand(item){
        var command_name = item.split(",",1)[0];
        //console.log(command_name.length);
        var command_text = item.slice(command_name.length+2,-1)

        return [command_name.slice(1,-1),command_text]


    }

    const [myArray, setlist1] = useState([]);
    function getList() {
         
        fetch(`https://s3.amazonaws.com/botbot2222.com/channels/${channel}.csv`,{cache: "no-store"}) // fetch text file
            .then((resp) => resp.text())
            .then(data => {
                const myList = data.split(/\r?\n/);

                setlist1([...myList]);
                //console.log(res)
            })
        
            
    }
    useEffect(() => {
        getList()
        
    },[]);

    //console.log(myArray);

    const SimpleList = ({ list }) => (
        
        <ul>
            <div className='table-container'>
                
                <table className="channel_table">
                    <tr>
                        <th>Command</th>
                        <th>Output</th>

                    </tr>
                    
                {list.map((item,index) => ((index !== list.length-1) && (item.split(",").length >= 2)) &&(
                    <tr>
                        <td>{getCommand(item)[0]}</td>
                        <td className='nobreak'>{getCommand(item)[1]}</td>
                    </tr>

                ))}
                </table>
            </div>
        </ul>
    );


    return (
        <>
            <h1 className='list-subheading'>{channel}'s Custom Commands</h1>
            <h2 className='list-subtitle'>To view global commands for all channels, visit the <Link to='/commands'>global command page.</Link></h2>

            <SimpleList list={myArray} />
        </>
    );

}


function Command_main() {

    return (
        <>
            <h1 className='list-title'>Botbot's Commands</h1>
            <h2 className='list-subtitle'>To view channel specific commands, check the <Link to='/channels'>channel list.</Link></h2>


            <h3 className='list-subheading'>Quotes</h3>


            <div className='table-container'>
                <table className="global_table">
                    <tr>
                        <th>Command</th>
                        <th>Platform</th>
                        <th>Description</th>

                    </tr>
                    <tr>
                        <td>!quote</td>
                        <td>
                            <img className="inline_img" src="/images/discord.png" alt="discord" />
                            <img className="inline_img" src="/images/twitch.png" alt="twitch" />
                        </td>
                        <td>Displays a random quote from the <Link to='/quotes'>quote list.</Link></td>
                    </tr>

                    <tr>
                        <td>!quote [number]</td>
                        <td>
                            <img className="inline_img" src="/images/discord.png" alt="discord" />
                            <img className="inline_img" src="/images/twitch.png" alt="twitch" />
                        </td>
                        <td>Displays a specific quote with the given number, if it exists.</td>
                    </tr>

                    <tr>
                        <td>!addquote [text]</td>
                        <td>
                            <img className="inline_img" src="/images/discord.png" alt="discord" />
                            <img className="inline_img" src="/images/twitch.png" alt="twitch" />
                        </td>
                        <td>Adds [text] to the quotelist.</td>
                    </tr>

                    <tr>
                        <td>!editquote [number] [new text]</td>
                        <td>
                            <img className="inline_img" src="/images/discord.png" alt="discord" />
                            <img className="inline_img" src="/images/twitch.png" alt="twitch" />
                        </td>
                        <td>Replaces the specified quote with [new text], if it exists.</td>
                    </tr>

                    <tr>
                        <td>!delquote [number]</td>
                        <td>
                            <img className="inline_img" src="/images/discord.png" alt="discord" />
                            <img className="inline_img" src="/images/twitch.png" alt="twitch" />
                        </td>
                        <td>Deletes a specific quote with the given number, if it exists.</td>
                    </tr>

                    <tr>
                        <td>!list / !quotelist</td>
                        <td>
                            <img className="inline_img" src="/images/discord.png" alt="discord" />
                            <img className="inline_img" src="/images/twitch.png" alt="twitch" />
                        </td>
                        <td>Provides a link to the <Link to='/quotes'>quote list.</Link></td>
                    </tr>

                    <tr>
                        <td>!rawlist</td>
                        <td>
                            <img className="inline_img" src="/images/discord.png" alt="discord" />
                        </td>
                        <td>Provides a raw copy of the <Link to='/quotes'>quote list</Link> to download.</td>
                    </tr>

                    <tr>
                        <td>!pending</td>
                        <td>
                            <img className="inline_img" src="/images/discord.png" alt="discord" />
                        </td>
                        <td>View a list of current quotes pending approval. Only verified users may permit quotes.</td>
                    </tr>
                </table>
            </div>


            <h3 className='list-subheading'>Custom Commands</h3>


            <div className='table-container'>
                <table className="global_table">
                    <tr>
                        <th>Command</th>
                        <th>Platform</th>
                        <th>Description</th>

                    </tr>

                    <tr>
                        <td>!help</td>
                        <td>
                            <img className="inline_img" src="/images/discord.png" alt="discord"/>
                            <img className="inline_img" src="/images/twitch.png" alt="twitch" />
                            </td>
                        <td>Provides a link to this page.</td>
                    </tr>

                    <tr>
                        <td>!commands</td>
                        <td>
                            <img className="inline_img" src="/images/twitch.png" alt="twitch" />
                        </td>
                        <td>Provides a link to the list of the channel's commands. Custom commands are exclusive to the channels they were created in.</td>
                    </tr>

                    

                    <tr>
                        <td>!addcmd [command] [command output]</td>
                        <td>
                        
                            <img className="inline_img" src="/images/twitch.png" alt="twitch" />
                        </td>
                        <td>Adds a command to the channel. The command can be called with [command] and will output [command output]. You may overwrite global botbot commands. You must be a moderator to add commands.</td>
                    </tr>

                    <tr>
                        <td>!editcmd [command] [command output]</td>
                        <td>
                        
                            <img className="inline_img" src="/images/twitch.png" alt="twitch" />
                        </td>
                        <td>Edits the given command, if it exists. You must be a moderator to edit commands.</td>
                    </tr>

                    <tr>
                        <td>!delcmd [command] </td>
                        <td>
                        
                            <img className="inline_img" src="/images/twitch.png" alt="twitch" />
                        </td>
                        <td>Removes the given command from the channel, if it exists. You must be a moderator to remove commands.</td>
                    </tr>

                    <tr>
                        <td>[command]</td>
                        <td>
                        
                            <img className="inline_img" src="/images/twitch.png" alt="twitch" />
                        </td>
                        <td>Activates the given custom command, if it exists. Note that an exclamation point (!) or other identifier is not necessary for custom commands.</td>
                    </tr>
                </table>
            </div>

            <h3 className='list-subheading'>Music</h3>


            <div className='table-container'>
                <table className="global_table">
                    <tr>
                        <th>Command</th>
                        <th>Platform</th>
                        <th>Description</th>

                    </tr>

                    <tr>
                        <td>!play [query]</td>
                        <td>
                            <img className="inline_img" src="/images/discord.png" alt="discord"/>
                            </td>
                        <td>Plays a youtube video in voice chat. [query] can be either a direct youtube link, video code, or terms to search for.</td>
                    </tr>

                    <tr>
                        <td>!nowplaying</td>
                        <td>
                        <img className="inline_img" src="/images/discord.png" alt="discord"/>
                        </td>
                        <td>Displays the currently playing audio.</td>
                    </tr>

                    

                    <tr>
                        <td>!queue</td>
                        <td>
                        
                        <img className="inline_img" src="/images/discord.png" alt="discord"/>
                        </td>
                        <td>Displays the queue of requests.</td>
                    </tr>

                    <tr>
                        <td>!skip</td>
                        <td>
                        
                        <img className="inline_img" src="/images/discord.png" alt="discord"/>
                        </td>
                        <td>Skips the current audio and plays the next.</td>
                    </tr>

                    <tr>
                        <td>!clear</td>
                        <td>
                        
                        <img className="inline_img" src="/images/discord.png" alt="discord"/>
                        </td>
                        <td>Clears the queue of requests and stops the currently playing audio.</td>
                    </tr>

                    <tr>
                        <td>!leave</td>
                        <td>
                        
                        <img className="inline_img" src="/images/discord.png" alt="discord"/>
                        </td>
                        <td>Removes the bot from voice chat and clears the queue.</td>
                    </tr>

                    <tr>
                        <td>!join</td>
                        <td>
                        
                        <img className="inline_img" src="/images/discord.png" alt="discord"/>
                        </td>
                        <td>Adds the bot to your current voice channel.</td>
                    </tr>
                </table>
            </div>


            <h3 className='list-subheading'>Other Commands</h3>
            <h2 className='list-subtitle'>the stupid ones (not a complete list)</h2>


            <div className='table-container'>
                <table className="global_table">
                    <tr>
                        <th>Command</th>
                        <th>Platform</th>
                        <th>Output</th>

                    </tr>

                    <tr>
                        <td>!gab</td>
                        <td>
                            <img className="inline_img" src="/images/discord.png" alt="discord" />
                            <img className="inline_img" src="/images/twitch.png" alt="twitch" />
                        </td>
                        <td>"brb"</td>
                    </tr>

                    <tr>
                        <td>!me</td>
                        <td>
                            <img className="inline_img" src="/images/discord.png" alt="discord" />
                            <img className="inline_img" src="/images/twitch.png" alt="twitch" />
                        </td>
                        <td>"You're [you] way to go buddy Kappa b"</td>
                    </tr>

                    <tr>
                        <td>!roll</td>
                        <td>
                            <img className="inline_img" src="/images/discord.png" alt="discord" />
                            <img className="inline_img" src="/images/twitch.png" alt="twitch" />
                        </td>
                        <td>Rolls a (not rigged) six-sided dice.</td>
                    </tr>

                    <tr>
                        <td>!8ball [question]</td>
                        <td>
                            <img className="inline_img" src="/images/discord.png" alt="discord" />
                            <img className="inline_img" src="/images/twitch.png" alt="twitch" />
                        </td>
                        <td>Asks the 8ball a question (also not rigged).</td>
                    </tr>



                    <tr>
                        <td>!info</td>
                        <td>
                            <img className="inline_img" src="/images/discord.png" alt="discord" />
                            <img className="inline_img" src="/images/twitch.png" alt="twitch" />
                        </td>
                        <td>Displays botbot's biography.</td>
                    </tr>
                </table>
            </div>

        </>




    );
}
export default Command