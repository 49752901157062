import '../../App.css'
import React from 'react';
import About from '../About';
import Navbar from '../../components/Navbar';

function Abouts() {
    return (
        <>
            <Navbar />
            <About />
        </>
    )
}

export default Abouts;