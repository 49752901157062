import React, {useState, useEffect} from 'react';
import './Quote.css';





function Quote() {


    //const quote_list = ["quote numero uno", "2nd quote", "3rd quote", "4th quote"];
    //let myArray = [];
    const [myArray, setlist1] = useState([]);
    const [myArray2, setlist2] = useState([]);
    /*
    const SimpleList = ({ list }) => (
        
        <ul>
            {list.map((item,index) => index !== list.length-1 && (
                <li className='list-elements' key={item.id}>{index+1}: {((item === "") ? "[deleted]" : item) }</li>
            ))}
        </ul>
    );
    */
    var [randomQuote, setRandomQuote] = useState([]);

    function getList() {
        console.log("HI DAD")
        fetch('https://s3.amazonaws.com/botbot2222.com/channels/quotesinfo.txt',{cache: "no-store"}) // fetch text file
            .then((resp) => resp.text())
            .then(data => {
                const myList = data.split(/\r?\n/);

                setlist1([...myList]);
            })
        fetch('https://s3.amazonaws.com/botbot2222.com/channels/daily_quote.txt',{cache: "no-store"}) // fetch text file
            .then((resp) => resp.text())
            .then(data => {
                const myList2 = data.split(/\r?\n/);

                setlist2([...myList2]);
            })
    }

    
    function getDailyQuote(){
        

        
        console.log(myArray2[0])

        randomQuote = myArray[myArray2[0]]

        if (randomQuote !== undefined){
            randomQuote = splitWithTail(randomQuote,",",4)[4]
            console.log("HI")
        }



        
        setRandomQuote(randomQuote);

    }

    let mybutton = document.getElementById("myBtn");

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () { scrollFunction() };

    function scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            mybutton.style.display = "block";
        } else {
            mybutton.style.display = "none";
        }
    }
    /*
    function getRandomQuote(){
        var date = new Date();
        var day = date.getUTCDate();
        var month = date.getUTCMonth();
        var year = date.getUTCFullYear();
        var curdate = parseInt(`${month}${day}${year}`);


        //var seed = Math.trunc((((Math.sqrt((parseInt(`${month}${day}${year}`)/Math.pi)) % 1) * 10000) % 1) * 10000);
        var seed = Math.trunc(((Math.sqrt(curdate / Math.PI)) % 1) * 10000);

        randomQuote = "";
        //console.log(randomQuote);

        randomQuote = myArray[seed % (myArray.length - 1)];
        while (randomQuote === "" || randomQuote === "\n"){
            console.log(randomQuote)

            seed+=1
            randomQuote = myArray[seed % (myArray.length - 1)];

        }
        setRandomQuote(randomQuote);
    }
    */

    function splitWithTail(str,delim,count){
        var parts = str.split(delim);
        var tail = parts.slice(count).join(delim);
        var result = parts.slice(0,count);
        result.push(tail);
        return result;
      }

    useEffect(() => {
        getList()
        
        
    },[]);

    useEffect(() => {
        getDailyQuote()
        
        
    },[myArray,myArray2]);



    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    };

    const scrollToTop = () => {
        window.scrollTo({
            top:0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour 
               in place of 'smooth' */
        });
    };
    /*
    <table className="customers">
                    <tr>
                        <th>ID</th>
                        <th>Submitter</th>
                        <th>Date</th>
                        
                        <th>Quote</th>

                    </tr>
                    
                {list.map((item,index) => (index !== list.length-1) &&(
                    <tr>
                        <td>{index+1}</td>
                        <td>{splitWithTail(item,",",4)[1]}</td>
                        <td>{splitWithTail(item,",",4)[0]}</td>
                        <td className='nobreak'>{(splitWithTail(item,",",4)[4] === "") ? "[deleted]" : splitWithTail(item,",",4)[4]}</td>
                    </tr>

                ))}
                </table>
    */
    const SimpleList = ({ list }) => (
        
        <ul>
            <div className='table-container'>
                
                <table className="customers">
                    <tr>
                        <th>ID</th>
                        <th>Date</th>
                        <th>Submitter</th>
                        
                        <th>Quote</th>

                    </tr>
                    
                {list.map((item,index) => (index !== list.length-1) &&(
                    <tr>
                       <td>{index+1}</td>
                        <td>{(splitWithTail(item,",",4)[1] === "") ? "-" : splitWithTail(item,",",4)[1]}</td>
                        <td>{(splitWithTail(item,",",4)[0] === "") ? "-" : splitWithTail(item,",",4)[0].toLowerCase()}</td>
                        <td className='nobreak'>{(splitWithTail(item,",",4)[4] === "") ? "[deleted]" : splitWithTail(item,",",4)[4]}</td>
                    </tr>

                ))}
                </table>
            </div>
        </ul>
    );
    

    return (
        <>
            <h1 className='list-title'>{myArray.length-1} Quotes</h1>
            <h1 className='QOTD'>QOTD: {randomQuote}</h1>
            <h2 id = "bottomBtn" className='list-subtitle' onClick={scrollToBottom}>Jump to bottom</h2>
            <SimpleList list={myArray} />
            <h2 className='list-subtitle' >_</h2>

            <button onClick={scrollToTop} id="myBtn" title="Go to top">Top</button>
            
        </>




    );
}
export default Quote