import React from 'react';
//import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './components/pages/Home'
import Splatfest from './components/pages/Splatfest'
import Quotes from './components/pages/Quotes'
import Channels from './components/pages/Channels'
import Commands from './components/pages/Commands'
import Addmes from './components/pages/Addmes'
import Abouts from './components/pages/Abouts'
import Peaches from './components/pages/Peaches'
import Peaches2 from './components/pages/Peaches2'






function App() {

  //const [showNav, setShowNav]

  
  return (
    <>
    
      <Router>
        
        <Routes>
          <Route path='/' exact element= {<Home />} />
          <Route path='/splat' exact element= {<Splatfest />} />
          <Route path='/quotes' exact element= {<Quotes />} />
          <Route path='/channels' exact element= {<Channels />} />
          <Route path='/commands' exact element= {<Commands />} />
          <Route path='/addme' exact element= {<Addmes />} />
          <Route path='/about' exact element= {<Abouts />} />
          <Route path='/peach' exact element= {<Peaches />} />
          <Route path='/peach2' exact element= {<Peaches2 />} />
          <Route path='/commands/:url_page' exact element={<Commands/>}
        />
        </Routes>
      </Router>

    </>
  );
}

export default App;
