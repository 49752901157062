import '../../App.css'
import React from 'react';
import Addme from '../Addme';
import Navbar from '../../components/Navbar';


function Addmes() {
    return (
        <>
            <Navbar />
            <Addme />
        </>
    )
}

export default Addmes;