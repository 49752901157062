import '../../App.css'
import React from 'react';
import Command from '../Command';
import Navbar from '../../components/Navbar';


function Commands() {
    return (
        <>
            <Navbar />
            <Command />
        </>
    )
}

export default Commands;