import '../../App.css'
import React from 'react';
import Splat from '../Splat';


function Splatfest() {
    return (
        <>
            <Splat />
        </>
    )
}

export default Splatfest;