import '../../App.css'
import React from 'react';
import Channel from '../Channel';
import Navbar from '../../components/Navbar';


function Channels() {
    return (
        <>
            <Navbar />
            <Channel />
        </>
    )
}

export default Channels;