import React from 'react'
import CardItem from './CardItem'
import './Cards.css';

function Cards() {
  return (
    <div className='cards'>
        <h1>Features</h1>
        <div className="cards__container">
            <div className="cards__wrapper">
                <ul className="cards__items">
                    <CardItem 
                    src='images/quoteimage_cropped.png' 
                    text="Record memorable moments in Twitch and Discord!" 
                    label='Quotes!' 
                    path='/quotes'/>
                    <CardItem 
                    src='images/botbot_custom.png' 
                    text="Create and edit custom commands!" 
                    label='Commands!' 
                    path='/commands'/>
                    <CardItem 
                    src='images/botbot_transparent.png' 
                    text="Gaming" 
                    label='Gaming' 
                    path='/about'/>
                </ul>
            </div>
        </div>
      
    </div>
  );
}

export default Cards
