import React, {useState, useEffect} from 'react';
import './Channel.css';
import {Link } from 'react-router-dom';





function Channel() {


    //const quote_list = ["quote numero uno", "2nd quote", "3rd quote", "4th quote"];
    //let myArray = [];
    const [myArray, setlist1] = useState([]);
    const SimpleList = ({ list }) => (
        
        <ul>
            {list.map((item,index)  => (index !== list.length-1) && (
                <Link to={`/commands/${item}`} style={{ textDecoration: 'none' }}><li className='list-elements' key={item.id}>{((item === "") ? "[deleted]" : item) }</li></Link>
            ))}
        </ul>
    );
    
    //const text = "d";

//<button onClick={() => {artists.push({id: nextId++,name: name,});}}>Add</button>
//<button onClick={() => {setArtists([...artists,{ id: nextId++, name: name }]);}}>Add</button>
    function getList() {

        
        fetch('https://s3.amazonaws.com/botbot2222.com/channels/botbotchannels.txt', {cache: "no-store"}) // fetch text file
            .then((resp) => resp.text())
            .then(data => {
                const myList = data.split(/\r?\n/);
                //console.log(myList);
                //for (let i = 0; i < myList.length; i++){
                //    myArray.push(myList[i]);
                //}
                setlist1([...myList]);
                
            }) 

        
    }

    useEffect(() => {
        getList()
        //console.log("AGAIN");
    },[]);
    



    return (
        <>
            <h1 className='list-title'>{myArray.length} Channels</h1>
            <h2 className='list-subtitle'>Click a channel to view it's custom commands.</h2>
            <SimpleList list={myArray} />
            
        </>




    );
}
export default Channel